/* eslint-disable jsx-a11y/control-has-associated-label */
import { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, Col, Row } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorTreatment, TypeErrorTreatment } from '../../utils';
import SpinnerPage from '../../components/SpinnerPage';
import Alert from '../../components/Alert';
import {
  EnumAlert,
  VoucherCategory,
  VoucherRestrictionType,
  VoucherType,
} from '../../enum';
import { VoucherCategoryOptions } from '../../enum/optionsEnum';
import { FormInput } from '../../components';
import FormInputSelect from '../../components/FormInputSelect';
import { ClientsService, VaucherService } from '../../service';
import FormInputNumberMask from '../../components/FormInputNumberMask';
import validationSchemaCreate from './validationSchemaCreate';
import FormInputMask from '../../components/FormInputMask';
import STATES from '../../constants/states';
import AlertModal from '../../components/Modals/AlertModal';

type ObjectSellectProps = {
  label: string;
  value: string;
};

type VoucherProps = {
  id?: string;
  name: string;
  category: VoucherCategory;
  clients: string[];
  states: string[];
  type?: VoucherType;
  value: string;
  restriction_type?: VoucherRestrictionType;
  restriction: string;
  active?: boolean;
  start_date: string;
  end_date: string;
};

type AlertModalProps = {
  show: boolean;
  title: string;
  text: string;
  type: string;
};

const voucherRestrictionTypeOptions = [
  { label: 'Limitado', value: VoucherRestrictionType.Limited },
  { label: 'Ilimitado', value: VoucherRestrictionType.Unlimited },
];

const voucherTypeOptions = [
  { label: 'Fixo', value: VoucherType.Fixed },
  { label: 'Percentual', value: VoucherType.Percentual },
];

const activeOptions = [
  { label: 'Ativo', value: true },
  { label: 'Inativo', value: false },
];

const defaultValues: VoucherProps = {
  name: '',
  category: VoucherCategory.All,
  restriction: '',
  clients: [],
  states: [],
  start_date: '',
  end_date: '',
  value: '',
};

const VoucherCreateAndDetails = (props: any): ReactElement => {
  const { t } = useTranslation();
  const { match } = props;
  const isEdit = !!match?.params?.id;
  const defaultAlert = {
    show: false,
    title: isEdit
      ? 'Edição Realizada Com Sucesso'
      : 'Cadastro Realizado Com Sucesso',
    text: `O voucher foi ${isEdit ? 'editado' : 'cadastrado'} com sucesso`,
    type: 'success',
  };
  const vaucherService = new VaucherService();
  const history = useHistory();
  const [showModalAlert, setShowModalAlert] =
    useState<AlertModalProps>(defaultAlert);
  const [errorsBack, setErrorsBack] = useState<TypeErrorTreatment[]>([]);
  const [loading, setLoading] = useState(false);
  const [sellersDocument, setSellersDocument] = useState<ObjectSellectProps[]>(
    []
  );

  const methods = useForm<any>({
    defaultValues,
    resolver: yupResolver(validationSchemaCreate),
  });
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    control,
    trigger,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = (data: VoucherProps, e: any) => {
    e.preventDefault();
    const model: VoucherProps = {
      ...data,
      clients: data.clients.map((item: any) => item?.value),
      states: data.states.map((item: any) => item?.value),
      start_date: `${data.start_date}T00:00:00`,
      end_date: `${data.end_date}T23:59:59`,
      restriction:
        !data.restriction || data.restriction === '' ? '0' : data.restriction,
    };
    if (isEdit) {
      vaucherService
        .putVoucher({ id: model?.id, active: model.active })
        .then(() => {
          setShowModalAlert({ ...showModalAlert, show: true });
        })
        .catch((errors: any) => setErrorsBack(ErrorTreatment(errors)));
    } else {
      vaucherService
        .postVoucher(model)
        .then(() => {
          setShowModalAlert({ ...showModalAlert, show: true });
        })
        .catch((errors: any) => setErrorsBack(ErrorTreatment(errors)));
    }
  };

  const getVoucher = (id: string, clients: ObjectSellectProps[]) => {
    setLoading(true);
    vaucherService
      .getVoucher(id)
      .then((resp: any) => {
        if (resp?.data) {
          const auxClients = resp?.data?.clients.map((item: any) => {
            return clients.find((x) => x.value === item);
          });
          const auxStates = resp?.data?.states.map((item: any) => {
            return STATES.find((x) => x.value === item);
          });
          reset({
            ...resp?.data,
            type: resp?.data?.value_type,
            start_date: new Date(resp?.data.start_date)
              .toISOString()
              .split('T')[0],
            end_date: new Date(resp?.data.end_date).toISOString().split('T')[0],
            clients: auxClients,
            states: auxStates,
          });
        }
      })
      .catch(() => {
        setShowModalAlert({
          ...showModalAlert,
          show: true,
          type: 'danger',
          title: 'Falha no Carregamento',
          text: 'Não foi possível carregar o voucher.',
        });
      })
      .finally(() => setLoading(false));
  };

  const getSellers = () => {
    const clientsService = new ClientsService();
    setLoading(true);
    clientsService
      .getClientsActives()
      .then((resp: any) => {
        const newValues: ObjectSellectProps[] = [];
        resp?.data.forEach((el: any) => {
          newValues.push({
            value: el.id,
            label: el.document,
          });
        });
        setSellersDocument(newValues);
        if (match?.params?.id) {
          getVoucher(match?.params?.id, newValues);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // eslint-disable-next-line
      getSellers();
  }, []);

  return (
    <>
      {loading && <SpinnerPage />}
      <form className="filter-requests" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm={12}>
            <div className="d-flex justify-content-between page-title-box">
              <h4 className="page-title">
                {`${
                  match?.params?.id ? 'Edição' : 'Cadastro'
                } de Cupom de Desconto`}
              </h4>
              <Button
                variant="secondary"
                className="btn-return"
                onClick={() => {
                  history.push('/voucher');
                }}>
                Voltar
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          {errorsBack &&
            errorsBack.map((error) => (
              <Col xl={{ span: 6, offset: 3 }} md={12} key={error.message}>
                <Alert
                  type={EnumAlert.Error}
                  text={t(error.message)}
                  remove={() => {
                    const arrayError = errorsBack.filter(
                      (x) => x.message !== error.message
                    );
                    setErrorsBack(arrayError);
                  }}
                />
              </Col>
            ))}
        </Row>

        <Card>
          <Card.Body>
            <Row>
              <Col
                sm={6}
                xl={getValues('category') !== VoucherCategory.All ? 6 : 8}>
                <FormInput
                  name="name"
                  required
                  disabled={isEdit}
                  label="Código do Cupom de Desconto"
                  containerClass="mb-3"
                  type="text"
                  register={register}
                  errors={errors?.name}
                />
              </Col>
              <Col
                sm={6}
                xl={getValues('category') !== VoucherCategory.All ? 2 : 4}>
                <FormInputSelect
                  name="category"
                  required
                  isDisabled={isEdit}
                  label="Categoria"
                  value={VoucherCategoryOptions.find(
                    (x) => x.value === getValues('category')
                  )}
                  onChange={(e: any) => {
                    setValue('category', e.value);
                    trigger('category');
                    if (e.value !== VoucherCategory.Client) {
                      setValue('clients', []);
                      trigger('clients');
                    }
                    if (e.value !== VoucherCategory.Region) {
                      setValue('states', []);
                      trigger('states');
                    }
                  }}
                  options={VoucherCategoryOptions}
                  errors={errors?.category}
                />
              </Col>
              {getValues('category') !== VoucherCategory.All && (
                <Col sm={6} xl={4}>
                  {getValues('category') === VoucherCategory.Client ? (
                    <FormInputSelect
                      required
                      isDisabled={isEdit}
                      name="clients"
                      key="clients"
                      label="CNPJ/CPF"
                      isMulti
                      value={getValues('clients')}
                      options={sellersDocument}
                      onChange={(e: any) => {
                        setValue('clients', e);
                        trigger('clients');
                      }}
                      errors={errors?.clients}
                    />
                  ) : (
                    <FormInputSelect
                      required
                      isDisabled={isEdit}
                      name="states"
                      key="states"
                      label="UF"
                      isMulti
                      value={getValues('states')}
                      options={STATES}
                      onChange={(e: any) => {
                        setValue('states', e);
                        trigger('states');
                      }}
                      errors={errors?.states}
                    />
                  )}
                </Col>
              )}
              <Col sm={6} xl={3}>
                <FormInput
                  required
                  disabled={isEdit}
                  name="start_date"
                  label="Período"
                  containerClass="mb-3"
                  type="date"
                  register={register}
                  errors={errors?.start_date}
                />
              </Col>
              <Col sm={6} xl={3}>
                <FormInput
                  required
                  disabled={isEdit}
                  name="end_date"
                  containerClass="mb-3"
                  type="date"
                  register={register}
                  errors={errors?.end_date}
                />
              </Col>
              <Col sm={6} xl={2}>
                <FormInputSelect
                  required
                  isDisabled={isEdit}
                  name="type"
                  label="Tipo de Valor"
                  value={voucherTypeOptions.find(
                    (x) => x.value === getValues('type')
                  )}
                  onChange={(e: any) => {
                    setValue('type', e.value);
                    setValue('value', '');
                    trigger('type');
                  }}
                  options={voucherTypeOptions}
                  errors={errors?.type}
                />
              </Col>
              <Col sm={6} xl={4}>
                <Controller
                  name="value"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <FormInputNumberMask
                      required
                      disabled={!getValues('type') || isEdit}
                      label="Valor"
                      name={name}
                      key="value"
                      maxValue={
                        getValues('type') === VoucherType.Percentual
                          ? 100
                          : 100000
                      }
                      value={value}
                      onChange={(e) => {
                        const val = e.target.value;
                        if (!Number.isNaN(val)) {
                          onChange(val);
                        } else {
                          onChange(undefined);
                        }
                      }}
                      decimalSeparator=","
                      errors={errors?.value}
                    />
                  )}
                />
              </Col>
              <Col
                sm={6}
                xl={
                  getValues('restriction_type') ===
                  VoucherRestrictionType.Limited
                    ? 6
                    : 8
                }>
                <FormInputSelect
                  required
                  isDisabled={isEdit}
                  name="restriction_type"
                  label="Limite de Uso"
                  value={voucherRestrictionTypeOptions.find(
                    (x) => x.value === getValues('restriction_type')
                  )}
                  onChange={(e: any) => {
                    setValue('restriction_type', e.value);
                    trigger('restriction_type');
                  }}
                  options={voucherRestrictionTypeOptions}
                  errors={errors?.restriction_type}
                />
              </Col>
              {getValues('restriction_type') ===
                VoucherRestrictionType.Limited && (
                <Col sm={6} xl={2}>
                  <FormInputMask
                    required
                    disabled={isEdit}
                    name="restriction"
                    label="Quantidade de Uso"
                    guide={false}
                    value={getValues('restriction')}
                    onChange={(e: any) => {
                      setValue('restriction', e?.target?.value);
                    }}
                    errors={errors?.restriction}
                    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                  />
                </Col>
              )}
              <Col sm={6} xl={4}>
                <FormInputSelect
                  required
                  name="active"
                  label="Status"
                  value={activeOptions.find(
                    (x) => x.value === getValues('active')
                  )}
                  onChange={(e: any) => {
                    setValue('active', e.value);
                    trigger('active');
                  }}
                  options={activeOptions}
                  errors={errors?.active}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Row>
          <Col xs={12} md={6} style={{ paddingBottom: '1rem' }} />
          <Col xs={12} md={3} style={{ paddingBottom: '1rem' }}>
            <Button
              variant="secondary"
              className="submit-button"
              onClick={() => history.push('/voucher')}>
              Cancelar
            </Button>
          </Col>

          <Col xs={12} md={3} style={{ paddingBottom: '1rem' }}>
            <Button variant="success" type="submit" className="submit-button">
              Salvar
            </Button>
          </Col>
        </Row>
        <AlertModal
          show={showModalAlert.show}
          visibleModal={() => {
            if (showModalAlert.type === 'success') {
              setShowModalAlert(defaultAlert);
              window.location.reload();
            } else {
              setShowModalAlert(defaultAlert);
              history.push('/voucher');
            }
          }}
          values={{
            title: showModalAlert.title,
            text: showModalAlert.text,
          }}
          type={showModalAlert.type}
        />
      </form>
    </>
  );
};

export default VoucherCreateAndDetails;
