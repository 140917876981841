import {
  EnumTransportStatus,
  EnumStateCode,
  LegalEntityType,
  EnumPaymentStatus,
  VoucherCategory,
  AccountType,
  CreatedByType,
  DateOfType,
  EnumScenario,
  EnumPaymentType,
  EnumServiceType,
  PixType,
  EnumOperationType,
  EnumReverseType,
  EnumStateCodeFrenet,
} from '.';

const OptionsEnumTransportStatus = [
  { value: EnumTransportStatus.Delivered, label: 'Entregue' },
  { value: EnumTransportStatus.InTransit, label: 'Em Trânsito' },
  { value: EnumTransportStatus.PendingCollect, label: 'Coleta Pendente' },
  { value: EnumTransportStatus.PendingSend, label: 'Envio Pendente' },
  { value: EnumTransportStatus.Received, label: 'Recebido' },
  { value: EnumTransportStatus.ToReceive, label: 'Receber' },
];

const EnumOptionsStateCode = [
  { label: 'Pendente Pagamento', value: EnumStateCode.Draft },
  { label: 'Aguardando Postagem', value: EnumStateCode.Ready },
  { label: 'Cancelado', value: EnumStateCode.Canceled },
  { label: 'Expirado', value: EnumStateCode.Expired },
  { label: 'Dropado', value: EnumStateCode.Dropped },
  { label: 'Em Entrega', value: EnumStateCode.InTransit },
  { label: 'Entregue', value: EnumStateCode.Delivered },
  { label: 'A Coletar', value: EnumStateCode.ToCollect },
  { label: 'Coletado Transportadora', value: EnumStateCode.CollectedByCarrier },
  { label: 'Devolução Dropada', value: EnumStateCode.DevolutionDropped },
  { label: 'Encomenda Devolvida', value: EnumStateCode.OrderReturned },
];

const EnumStateCodeColors = [
  { label: 'Pagamento Aprovado', value: '#26b68e' },
  { label: 'Pagamento Cancelado', value: '#fa5f7e' },
  { label: 'Pagamento Rejeitado', value: '#fa5f7e' },
  { label: 'Pagamento Estornado', value: '#fa5f7e' },
  { label: 'Pendente Pagamento', value: '#fdc62d' },
  { label: 'Pagamento em Análise', value: '#fdc62d' },
  { label: 'Pagamento Expirado', value: '#fa5f7e' },
  { label: 'Aguardando Postagem', value: '#8e9cab' },
  { label: 'Cancelado', value: '#fa5f7e' },
  { label: 'Expirado', value: '#fa5f7e' },
  { label: 'Dropado', value: '#26b68e' },
  { label: 'Em Entrega', value: '#26b68e' },
  { label: 'Entregue', value: '#26b68e' },
  { label: 'A Coletar', value: '#fdc62d' },
  { label: 'Coletado Transportadora', value: '#fdc62d' },
  { label: 'Devoluçao Droppada', value: '#26b68e' },
  { label: 'Encomenda Devolvida', value: '#26b68e' },
];

const EnumStatesCodeAndPayment = [
  {
    value: 1.0,
    label: 'Pendente Pagamento',
    color: '#fdc62d',
    status: EnumStateCode.Draft,
    payment_status: EnumPaymentStatus.None,
  },
  {
    value: 1.1,
    label: 'Pagamento em Análise',
    color: '#fdc62d',
    status: EnumStateCode.Draft,
    payment_status: EnumPaymentStatus.Pending,
  },
  {
    value: 1.4,
    label: 'Pagamento Rejeitado',
    color: '#fa5f7e',
    status: EnumStateCode.Draft,
    payment_status: EnumPaymentStatus.Rejected,
  },
  {
    value: 1.6,
    label: 'Pendente Pagamento',
    color: '#fdc62d',
    status: EnumStateCode.Draft,
    payment_status: EnumPaymentStatus.Expired,
  },
  {
    value: 3.6,
    label: 'Pagamento Expirado',
    color: '#fa5f7e',
    status: EnumStateCode.Canceled,
    payment_status: EnumPaymentStatus.Expired,
  },
  {
    value: 3.3,
    label: 'Encomenda Cancelada',
    color: '#fa5f7e',
    status: EnumStateCode.Canceled,
    payment_status: EnumPaymentStatus.Canceled,
  },
  {
    value: 2.2,
    label: 'Aguardando Drop',
    color: '#fdc62d',
    status: EnumStateCode.Ready,
    payment_status: EnumPaymentStatus.Approved,
  },
  {
    value: 4.2,
    label: 'Expirado',
    color: '#fa5f7e',
    status: EnumStateCode.Expired,
    payment_status: EnumPaymentStatus.Approved,
  },
  {
    value: 3.5,
    label: 'Estornado',
    color: '#fa5f7e',
    status: EnumStateCode.Canceled,
    payment_status: EnumPaymentStatus.Reversal,
  },
  {
    value: 13.2,
    label: 'Em processamento',
    color: '#fdc62d',
    status: EnumStateCode.ProcessingDrop,
    payment_status: EnumPaymentStatus.Approved,
  },
  {
    value: 5.2,
    label: 'Dropado',
    color: '#26b68e',
    status: EnumStateCode.Dropped,
    payment_status: EnumPaymentStatus.Approved,
  },
  {
    value: 6.2,
    label: 'Em Entrega',
    color: '#26b68e',
    status: EnumStateCode.InTransit,
    payment_status: EnumPaymentStatus.Approved,
  },
  {
    value: 7.2,
    label: 'Entregue',
    color: '#26b68e',
    status: EnumStateCode.Delivered,
    payment_status: EnumPaymentStatus.Approved,
  },
  {
    value: 9.2,
    label: 'Coletado Transportadora',
    color: '#26b68e',
    status: EnumStateCode.CollectedByCarrier,
    payment_status: EnumPaymentStatus.Approved,
  },
  {
    value: 10.2,
    label: 'Devolução Dropada',
    color: '#26b68e',
    status: EnumStateCode.DevolutionDropped,
    payment_status: EnumPaymentStatus.Approved,
  },
  {
    value: 11.2,
    label: 'Encomenda Devolvida',
    color: '#26b68e',
    status: EnumStateCode.OrderReturned,
    payment_status: EnumPaymentStatus.Approved,
  },
  {
    value: 8.2,
    label: 'A coletar',
    color: '#26b68e',
    status: EnumStateCode.ToCollect,
    payment_status: EnumPaymentStatus.Approved,
  },
  {
    value: 14.0,
    label: 'Pedido Extraviado',
    color: '#fa5f7e',
    status: EnumStateCode.Lost,
    payment_status: EnumPaymentStatus.None,
  },
];

const EnumStatesCodeAndPaymentForSeller = [
  {
    value: 13,
    label: 'Em Processamento',
    color: '#fdc62d',
    status: EnumStateCode.ProcessingDrop,
    payment_status: EnumPaymentStatus.Approved,
  },
  {
    value: 5,
    label: 'Dropado',
    color: '#26b68e',
    status: EnumStateCode.Dropped,
    payment_status: EnumPaymentStatus.Approved,
  },
  {
    value: 7,
    label: 'Entregue',
    color: '#26b68e',
    status: EnumStateCode.Delivered,
    payment_status: EnumPaymentStatus.Approved,
  },
  {
    value: 10,
    label: 'Devolução Dropada',
    color: '#26b68e',
    status: EnumStateCode.DevolutionDropped,
    payment_status: EnumPaymentStatus.Approved,
  },
  {
    value: 11,
    label: 'Encomenda Devolvida',
    color: '#26b68e',
    status: EnumStateCode.OrderReturned,
    payment_status: EnumPaymentStatus.Approved,
  },
];

const EnumPaymentMethod = [
  { label: 'Pix', value: EnumPaymentType.PIX },
  { label: 'Cartão de Crédito', value: EnumPaymentType.CreditCard },
];

const EnumOptionsLegalEntityType = [
  { label: 'EPP', value: LegalEntityType.EPP },
  { label: 'ME', value: LegalEntityType.ME },
  { label: 'MEi', value: LegalEntityType.MEI },
  { label: 'Não Optante', value: LegalEntityType.None },
];

const EnumOptionsPayment = [
  { label: 'Pagamento em Análise', value: EnumPaymentStatus.Pending },
  { label: 'Pagamento Pendente', value: EnumPaymentStatus.None },
  { label: 'Pagamento Aprovado', value: EnumPaymentStatus.Approved },
  { label: 'Pagamento Cancelado', value: EnumPaymentStatus.Canceled },
  { label: 'Pagamento Rejeitado', value: EnumPaymentStatus.Rejected },
  { label: 'Pagamento Estornado', value: EnumPaymentStatus.Reversal },
  { label: 'Pagamento Expirado', value: EnumPaymentStatus.Expired },
];

const EnumPaymentColors = [
  { label: 'Pagamento Pendente', value: '#fdc62d' },
  { label: 'Pagamento Rejeitado', value: '#E87960' },
];

const EnumOptionsAccountType = [
  { label: 'Corrente', value: AccountType.ContaCorrente },
  { label: 'Poupança', value: AccountType.Poupança },
];

const EnumOptionsPixType = [
  { label: 'Documento', value: PixType.Document },
  { label: 'E-mail', value: PixType.Email },
  { label: 'Telefone', value: PixType.Phone },
  { label: 'Aleatória', value: PixType.Random },
];

const VoucherCategoryOptions = [
  { label: 'Todos', value: VoucherCategory.All },
  { label: 'Seller', value: VoucherCategory.Client },
  { label: 'Região', value: VoucherCategory.Region },
];

const CreatedByTypeOptions = [
  { label: 'Marketplace', value: CreatedByType.Marketplace },
  { label: 'Seller', value: CreatedByType.Client },
];

const DateOfTypeOptions = [
  { label: 'Criação', value: DateOfType.Created },
  { label: 'Drop-off', value: DateOfType.DropOff },
  { label: 'Entrega', value: DateOfType.Delivered },
];

const ScenarioOptions = [
  { label: '1', value: EnumScenario.Scenario1 },
  { label: '2', value: EnumScenario.Scenario2 },
  { label: '3', value: EnumScenario.Scenario3 },
  { label: '4', value: EnumScenario.Scenario4 },
  { label: '5', value: EnumScenario.Scenario5 },
  { label: '6', value: EnumScenario.Scenario6 },
];

const EnumChangeStatusPoint = {
  PointActivation: 1,
  ReceivingPackages: 2,
  PointDisplay: 3,
};

const ChangeStatusPointOptions = [
  {
    label: 'o status Ativar/Desativar.',
    value: EnumChangeStatusPoint.PointActivation,
  },
  {
    label: 'a propriedade Aceitando Pedidos.',
    value: EnumChangeStatusPoint.ReceivingPackages,
  },
  {
    label: 'a propriedade Ocultar/Exibir.',
    value: EnumChangeStatusPoint.PointDisplay,
  },
];

const ServiceTypeOptions = [
  { label: 'Devolução', value: EnumServiceType.Devolution },
  { label: 'Entrega', value: EnumServiceType.Delivery },
  { label: 'Reversa', value: EnumServiceType.Reverse },
];

const ActiveOptions = [
  { label: 'Ativo', value: true },
  { label: 'Inativo', value: false },
];

const OperationOptions = [
  { label: 'First mile', value: EnumOperationType.FirstMile },
  { label: 'Last mile', value: EnumOperationType.LastMile },
];

const ReversaOptions = [
  { label: 'Nenhum', value: EnumReverseType.None },
  { label: 'Reversa', value: EnumReverseType.Normal },
  { label: 'Labelfree', value: EnumReverseType.Labeless },
];

const EnumStatusFrenetOptions = [
  {
    label: 'Desconhecido',
    value: EnumStateCodeFrenet.Unknown,
  },
  {
    label: 'Criado',
    value: EnumStateCodeFrenet.Criado,
  },
  {
    label: 'Pagamento Pendente',
    value: EnumStateCodeFrenet.PendentePagamento,
  },
  {
    label: 'Falha no Pagamento',
    value: EnumStateCodeFrenet.FalhaPagamento,
  },
  {
    label: 'Pago',
    value: EnumStateCodeFrenet.SucessoPagamento,
  },
  {
    label: 'Postado',
    value: EnumStateCodeFrenet.Postado,
  },
  {
    label: 'Cancelamento Agendado',
    value: EnumStateCodeFrenet.CancelamentoAgendado,
  },
  {
    label: 'Cancelado',
    value: EnumStateCodeFrenet.Cancelado,
  },
  {
    label: 'Excluído',
    value: EnumStateCodeFrenet.Excluido,
  },
];
export {
  OptionsEnumTransportStatus,
  EnumOptionsStateCode,
  EnumStateCodeColors,
  EnumOptionsLegalEntityType,
  EnumOptionsPayment,
  EnumPaymentColors,
  EnumStatesCodeAndPayment,
  VoucherCategoryOptions,
  CreatedByTypeOptions,
  EnumOptionsAccountType,
  EnumOptionsPixType,
  DateOfTypeOptions,
  ScenarioOptions,
  EnumPaymentMethod,
  EnumChangeStatusPoint,
  ChangeStatusPointOptions,
  ServiceTypeOptions,
  ActiveOptions,
  OperationOptions,
  ReversaOptions,
  EnumStatesCodeAndPaymentForSeller,
  EnumStatusFrenetOptions,
};
