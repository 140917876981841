import { ReactElement, useEffect, useState } from 'react';
import { Col, Row, Table as BootstrapTable } from 'react-bootstrap';

import FormInput from '../../FormInput';
import Spinner from '../../Spinner';

type TableProps = {
  header: string[];
  attributes: string[];
  data: any[];
  getSelecteds?: any;
  getSelected?: any;
  loading: boolean;
  marginBottom?: boolean;
  getSelectedAllUnmarred?: boolean;
};

const Table = ({
  header,
  attributes,
  data,
  getSelecteds,
  getSelected,
  loading,
  marginBottom = true,
  getSelectedAllUnmarred = false,
}: TableProps): ReactElement => {
  const [auxData, setAuxData] = useState(data);
  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    setAuxData(data);
  }, [data]);

  return (
    <div style={marginBottom ? { marginBottom: '1em' } : {}}>
      <BootstrapTable striped hover responsive>
        <thead>
          <tr>
            {getSelecteds && (
              <th aria-label="selecteds">
                <FormInput
                  name="check_selected"
                  type="checkbox"
                  checked={allSelected}
                  onChange={() => {
                    let newData = [];
                    if (!allSelected === true) {
                      newData = auxData.map((item) => {
                        return { ...item, selected: true };
                      });
                      getSelecteds(newData);
                    } else {
                      newData = auxData.map((item) => {
                        return { ...item, selected: false };
                      });
                      getSelecteds(getSelectedAllUnmarred ? newData : []);
                    }
                    setAuxData(newData);
                    setAllSelected(!allSelected);
                  }}
                />
              </th>
            )}
            {header.map((item) => (
              <th key={item}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {auxData.map((line, i) => (
            <tr key={line[Object.keys(line)[0]]}>
              {getSelecteds && (
                <td aria-label="selecteds">
                  <FormInput
                    name={`${i}_check_selected`}
                    type="checkbox"
                    checked={line.selected || false}
                    onChange={() => {
                      const newData = [...auxData];
                      newData[i].selected = !line.selected;
                      setAuxData(newData);
                      getSelecteds(newData.filter((x) => x.selected === true));
                      if (getSelected) getSelected(newData[i]);
                    }}
                  />
                </td>
              )}
              {Object.values(attributes).map((item) => (
                <td
                  style={{
                    color:
                      item === 'status_code_label' ? line.statusCodeColor : '',
                  }}
                  key={item}>
                  {line[item]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </BootstrapTable>
      {auxData.length === 0 && !loading && (
        <Row>
          <Col xs={12}>
            <div className="text-center">
              <h3>Sem resultados</h3>
            </div>
          </Col>
        </Row>
      )}
      {loading && (
        <Row>
          <Col xs={12}>
            <div className="text-center">
              <Spinner
                className="spinner-border-sm me-1"
                tag="span"
                size="sm"
                color="primaryG"
              />
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Table;
