import { ReactElement, ChangeEvent } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SelectAsync from 'react-select/async';
import Select from 'react-select';

type FormInputSelectProps = {
  label?: string;
  tooltipText?: string;
  name: string;
  id?: string;
  errors?: any;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  isMulti?: boolean;
  options?: any;
  loadOptions?: (e: string) => void;
  isDisabled?: boolean;
  value?: any;
  defaultValue?: any;
  isClearable?: boolean;
  editIcon?: boolean;
  placeholder?: string;
  isAsync?: boolean;
  background?: string;
  color?: string;
  notHoverColor?: boolean;
  labelClassName?: string;
  noOptionsMessage?: () => string;
};

const FormInputSelect = ({
  noOptionsMessage,
  label,
  tooltipText,
  name,
  errors,
  onChange,
  required,
  isMulti = false,
  options,
  value,
  isDisabled,
  defaultValue,
  isClearable,
  editIcon = false,
  placeholder,
  isAsync = false,
  background = '#FFF',
  color = '#004D59',
  notHoverColor,
  labelClassName,
  loadOptions,
  ...otherProps
}: FormInputSelectProps): ReactElement => {
  const customStyles = {
    control: (styles: any, state: any) => ({
      ...styles,
      background,
      textColor: '#FFF',
      fontSize: '11px',
      borderColor: state.isFocused ? '#c8cbcf' : errors ? '#fa5c7c' : '#dee2e6',
      boxShadow: state.isFocused ? '#c8cbcf' : '',
      '&:hover': {
        borderColor: '',
      },
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      color: 'hsl(0, 0%, 80%) !important',
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color,
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color,
    }),
    option: (styles: any) => ({
      ...styles,
      fontSize: '11px',
      background: notHoverColor ? '#FFF !important' : styles.backgorund,
      cursor: notHoverColor ? 'pointer' : styles.cursor,
    }),
  };

  return (
    <div className="form-group mb-3">
      {label && (
        <Form.Label className={labelClassName || ''}>
          {label}
          {editIcon && <i className="uil-pen" style={{ marginLeft: '5px' }} />}
          {tooltipText && (
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={<Tooltip id="tooltip-top">{tooltipText}</Tooltip>}>
              <i className="uil-info-circle" style={{ marginLeft: '5px' }} />
            </OverlayTrigger>
          )}
        </Form.Label>
      )}

      {!isAsync ? (
        <Select
          cacheOptions
          styles={customStyles}
          isClearable={isClearable}
          id={name}
          name={name}
          noOptionsMessage={noOptionsMessage}
          data-testid={name}
          options={options}
          isDisabled={isDisabled}
          value={value}
          placeholder={placeholder || 'Selecione...'}
          onChange={onChange}
          isMulti={isMulti}
          defaultValue={defaultValue}
          {...otherProps}
        />
      ) : (
        <SelectAsync
          styles={customStyles}
          isClearable={isClearable}
          id={name}
          name={name}
          data-testid={name}
          isDisabled={isDisabled}
          value={value}
          placeholder={placeholder || 'Selecione...'}
          noOptionsMessage={() => 'Sem Resultado'}
          onChange={onChange}
          loadOptions={(e) => loadOptions && loadOptions(e)}
          isMulti={isMulti}
          defaultValue={defaultValue}
          {...otherProps}
        />
      )}

      {errors ? (
        <div role="alert" className="select-invalid-feedback">
          {errors.message}
        </div>
      ) : null}
      {!errors && required && (
        <div className="required-in-form">Obrigatório</div>
      )}
    </div>
  );
};

export default FormInputSelect;
