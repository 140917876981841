import HttpCarriersService from '../../config/HttpCarriers';

export class CarriersService {
  api: HttpCarriersService;

  constructor() {
    this.api = new HttpCarriersService();
  }

  getCarrier = (id: string) => {
    return this.api.get(`v1/carriers/${id}`);
  };

  getActiveCarriers = () => {
    return this.api.get(`v1/carriers`);
  };

  postShippingRates = (name: string, payload: any) => {
    return this.api.post(`v1/carriers/${name}/shipping-rates`, payload);
  };
}
