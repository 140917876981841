const CONST_LOCALSTORAGE = {
  PAYMENTS_ORDERS: 'PaymentsOrders',
  CART_INFORMATION: 'CartInformation',
};

const CONST_FILTERS = {
  ORDERS: 'FilterOrder',
  POINTS: 'FilterPointDrops',
  POINTSCAPTURES: 'FilterPointCapturesDrops',
  PANEL: 'FilterPanelOperation',
  SELLERORDERS: 'FilterSellerOrders',
  SIGNATORIES: 'FilterSignatories',
};

export { CONST_LOCALSTORAGE, CONST_FILTERS };
