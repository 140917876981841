// @flow
import { ReactElement, ChangeEvent } from 'react';
import {
  Button,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import MaskedInput from 'react-text-mask';

type FormInputProps = {
  label?: string;
  tooltipText?: string;
  type?: string;
  name: string;
  placeholder?: string;
  register?: any;
  errors?: any;
  refCallback?: any;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  mask?: any;
  required?: boolean;
  guide?: boolean;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  value?: any;
  defaultValue?: any;
  onClick?: any;
  onClickBtnVariant?: string;
  tooltipTextBtn?: string;
  editIcon?: boolean;
};

const FormInputMask = ({
  label,
  tooltipText,
  type,
  name,
  placeholder,
  register,
  errors,
  refCallback,
  onChange,
  mask,
  required,
  guide = true,
  onBlur,
  disabled = false,
  value,
  defaultValue,
  onClick,
  onClickBtnVariant,
  tooltipTextBtn,
  editIcon = false,
  ...otherProps
}: FormInputProps): ReactElement => {
  const classInvalid = `form-control ${
    errors ? 'was-validated form-control:invalid form-control is-invalid' : ''
  }`;

  const ViewBtn = () => {
    if (onClick && tooltipTextBtn) {
      return (
        <OverlayTrigger
          key="top"
          placement="top"
          overlay={<Tooltip id="tooltip-top">{tooltipTextBtn}</Tooltip>}>
          <Button
            variant={onClickBtnVariant || 'secondary'}
            id="button-addon2"
            onClick={onClick}>
            <i className="mdi mdi-magnify search-icon" />
          </Button>
        </OverlayTrigger>
      );
    }
    if (onClick) {
      return (
        <Button
          variant={onClickBtnVariant || 'secondary'}
          id="button-addon2"
          onClick={onClick}>
          <i className="mdi mdi-magnify search-icon" />
        </Button>
      );
    }
    return null;
  };

  return (
    <div className="form-group mb-3">
      <Form.Label>
        {label}
        {editIcon && <i className="uil-pen" style={{ marginLeft: '5px' }} />}
        {tooltipText && (
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={<Tooltip id="tooltip-top">{tooltipText}</Tooltip>}>
            <i className="uil-info-circle" style={{ marginLeft: '5px' }} />
          </OverlayTrigger>
        )}
      </Form.Label>

      <InputGroup>
        <MaskedInput
          onBlur={onBlur}
          mask={mask}
          className={classInvalid}
          type={type}
          placeholder={placeholder}
          name={name}
          guide={guide}
          data-testid={name}
          id={name}
          disabled={disabled}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          ref={(r: any) => {
            if (refCallback) refCallback(r);
          }}
          {...(register ? register(name) : {})}
          {...otherProps}
          autoComplete={name}
        />

        {ViewBtn()}

        {errors ? (
          <div
            role="alert"
            className="invalid-feedback"
            style={{ width: '105%' }}>
            {errors.message}
          </div>
        ) : null}
        {!errors && required && (
          <div className="required-in-form">Obrigatório</div>
        )}
      </InputGroup>
    </div>
  );
};

export default FormInputMask;
