// @flow
import { ReactElement } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';

import { FormInput } from '../../../components';
import Spinner from '../../../components/Spinner';
import FormInputMask from '../../../components/FormInputMask';
import { maskCpfOrCnpj } from '../../../utils/masks';

type FilterProps = {
  getValues: any;
  loading: boolean;
};

export type FilterMarketplaceProps = {
  responsible_name?: string;
  document_number?: string;
  name?: string;
};

const Filter = ({ getValues, loading }: FilterProps): ReactElement => {
  const methods = useForm<any>({});
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const documento = watch('document_number');

  const onSubmit = (data: FilterMarketplaceProps, e: any) => {
    e.preventDefault();
    getValues(data);
  };

  return (
    <form className="filter-requests" onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col sm={6} xl={3}>
          <FormInputMask
            name="document_number"
            key="document_number"
            label="CPF/CNPJ"
            mask={maskCpfOrCnpj}
            value={documento}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const val = e.target.value.replace(/[^\d]/g, '');
              setValue('document_number', val);
            }}
            errors={errors && errors.document}
          />
        </Col>
        <Col sm={6} xl={3}>
          <FormInput
            name="name"
            label="Nome Marketplace"
            containerClass="mb-3"
            type="text"
            register={register}
          />
        </Col>
        <Col sm={6} xl={3}>
          <FormInput
            name="responsible_name"
            label="Nome do Responsável"
            containerClass="mb-3"
            type="text"
            register={register}
          />
        </Col>
        <Col sm={2} xl={2}>
          {loading ? (
            <Button
              disabled
              className="button-inline-requests btn-icon"
              variant="success">
              <Spinner
                className="spinner-border-sm me-1"
                tag="span"
                color="white"
              />
            </Button>
          ) : (
            <Button
              className="button-inline-requests btn-icon"
              variant="success"
              type="submit">
              <i className="uil uil-search" />
            </Button>
          )}
        </Col>
        <Col sm={2} xl={1}>
          <Button
            className="button-inline-requests btn-icon"
            variant="secondary"
            onClick={() => {
              setValue('name', undefined);
              setValue('document_number', '');
              setValue('responsible_name', undefined);
            }}
            type="button">
            <i className="uil uil-sync" />
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default Filter;
