// @flow
import { ReactElement } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormInput } from '../../../components';
import Spinner from '../../../components/Spinner';
import FormInputSelect from '../../../components/FormInputSelect';
import { VoucherCategory } from '../../../enum';
import { VoucherCategoryOptions } from '../../../enum/optionsEnum';
import validationSchemaFilter from '../validationSchemaFilter';

type FilterProps = {
  getValues: any;
  loading: boolean;
};

export type FilterVoucherProps = {
  name?: string;
  category?: VoucherCategory;
  start_date?: string;
  end_date?: string;
  active?: boolean;
};

const activeOptions = [
  { label: 'Selecione...', value: null },
  { label: 'Ativo', value: true },
  { label: 'Inativo', value: false },
];

const newVoucherCategoryOptions = [
  { label: 'Selecione...', value: null },
  ...VoucherCategoryOptions,
];

const dateInit = new Date();
dateInit.setDate(dateInit.getDate() - 1);

const Filter = ({ getValues, loading }: FilterProps): ReactElement => {
  const methods = useForm<any>({
    resolver: yupResolver(validationSchemaFilter),
  });
  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = methods;

  const category = watch('category');
  const active = watch('active');
  const start_date = watch('start_date');
  const end_date = watch('end_date');

  const onSubmit = (data: FilterVoucherProps, e: any) => {
    e.preventDefault();
    getValues(data);
  };

  return (
    <form className="filter-requests" onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col sm={6} xl={4}>
          <FormInput
            name="name"
            label="Código Cupom de Desconto"
            containerClass="mb-3"
            type="text"
            register={register}
          />
        </Col>
        <Col sm={6} xl={4}>
          <FormInputSelect
            name="category"
            key="category"
            id="category"
            label="Categoria"
            isClearable
            value={newVoucherCategoryOptions.find((x) => x.value === category)}
            onChange={(e: any) => {
              setValue('category', e?.value || undefined);
              trigger('category');
            }}
            options={newVoucherCategoryOptions}
          />
        </Col>
        <Col sm={10} xl={4}>
          <FormInputSelect
            name="active"
            key="active"
            id="active"
            label="Status"
            isClearable
            value={activeOptions.find((x) => x.value === active)}
            options={activeOptions}
            onChange={(e: any) => {
              setValue('active', e?.value !== undefined ? e?.value : undefined);
            }}
          />
        </Col>
        <Col sm={6} xl={4}>
          <FormInput
            name="start_date"
            type="date"
            label="Período"
            value={start_date}
            onChange={(e: any) => {
              setValue(
                'isStartDate',
                e?.target?.value &&
                  e?.target?.value !== '' &&
                  e?.target?.value !== null
              );
              setValue('start_date', e?.target?.value);
              trigger(['start_date', 'end_date']);
            }}
            containerClass="mb-3"
            errors={errors?.start_date}
          />
        </Col>
        <Col sm={6} xl={4}>
          <FormInput
            name="end_date"
            type="date"
            value={end_date}
            onChange={(e: any) => {
              setValue(
                'isEndDate',
                e?.target?.value &&
                  e?.target?.value !== '' &&
                  e?.target?.value !== null
              );
              setValue('end_date', e?.target?.value);
              trigger(['start_date', 'end_date']);
            }}
            containerClass="mb-3"
            errors={errors?.end_date}
          />
        </Col>
        <Col sm={2} xl={2}>
          <Button
            className="button-inline-requests btn-icon"
            variant="secondary"
            onClick={() => {
              setValue('name', undefined);
              setValue('category', null);
              setValue('active', null);
              setValue('isStartDate', false);
              setValue('start_date', '');
              setValue('isEndDate', false);
              setValue('end_date', '');
            }}
            type="button">
            <i className="uil uil-sync" />
          </Button>
        </Col>
        <Col sm={2} xl={2}>
          {loading ? (
            <Button
              disabled
              className="button-inline-requests btn-icon"
              variant="success">
              <Spinner
                className="spinner-border-sm me-1"
                tag="span"
                color="white"
              />
            </Button>
          ) : (
            <Button
              className="button-inline-requests btn-icon"
              variant="success"
              type="submit">
              <i className="uil uil-search" />
            </Button>
          )}
        </Col>
      </Row>
    </form>
  );
};

export default Filter;
