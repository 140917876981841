import { Route } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import Login from '../pages/account/Login';
import Spinner from '../components/Spinner';

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticated, isLoading, getAccessTokenSilently, logout } = useAuth0();
    const [loading, setLoading] = useState(true);


    useEffect(() => {
      if (isAuthenticated) {
        getAccessTokenSilently()
        .then((resp) => {
          localStorage.setItem('token', resp);
        })
        .finally(() => setLoading(false));
      }
    }, [isAuthenticated, getAccessTokenSilently]);


    return (
        <Route
        {...rest}
        render={(props) => {
                if (isLoading) {
                  return (
                    <div className="d-flex justify-content-center spinner-loading">
                        <Spinner />
                      </div>
                    );
                  }
                  if (!isAuthenticated) {
                    const urlError = window.location.href.toString();
                    const error = urlError.includes('error=access_denied');
                    console.log(error)
                    if (error) logout();

                    return <Login />
                  }

                  if (!loading && isAuthenticated) {
                    return <Component {...props} />;
                  }

                return <div />
            }}
        />
    );
};

export default PrivateRoute;
