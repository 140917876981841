// @flow
import { ReactElement, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import classNames from 'classnames';

type AlertModalProps = {
  type: string;
  values: {
    title: string;
    text: string | ReactElement;
    textButton?: string;
  };
  visibleModal: () => void;
  show?: boolean;
  buttonType?: string;
};

const AlertModal = ({
  type,
  values,
  visibleModal,
  show,
  buttonType,
}: AlertModalProps): ReactElement => {
  /*
    classNames: success, info, warning e danger
    */
  useEffect(() => {
    return () => {
      // Ao desmontar o componente
    };
  }, []);

  return (
    <Modal show={show}>
      <Modal.Header
        closeButton
        className={classNames('modal-colored-header', `bg-${type}`)}>
        <h4 className="modal-title">{values.title}</h4>
      </Modal.Header>
      <Modal.Body>
        <p className="mt-3">{values.text}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={buttonType || type} onClick={() => visibleModal()}>
          {values.textButton ? values.textButton : 'Continuar'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AlertModal;
